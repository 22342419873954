import React, { useState, useEffect,useCallback ,useMemo} from "react";
import { useParams , useLocation} from "react-router-dom";
import {  DashMain, DashMainContent, DListimg,Loader } from "../bills/billsElements";
import Sidebar from "../bills/Sidebar";
import Modal from "../modal/modal";
// import { useRouter } from 'next/router'
import axios from "axios";
// import Script from 'next/script'
import { isBrowser } from "react-device-detect";
import "../Home/PaymentRequest.css"

import Skeleton from 'react-loading-skeleton'

// import Box from '@mui/material/Box';
import FormControl,{ useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { 
        MenuItem, 
        Select, 
        TextField, 
        Typography,
        Grid,
        CircularProgress,
        circularProgressClasses
    } from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader"
import PaymentModal from './../modal/paymentType';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon   from '@mui/icons-material/Close';


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  });
NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const PaymentRequest = (props) => {

    const [billServiceId,setBillServiceId]=useState('');
    const [bills,setBills]=useState('');
    const [selectedBiller,setselectedBiller]=useState('');
    const [amount_,setamount]=useState(0);
    const [email,setemail]=useState('');
    const [phone,setphone]=useState('');
    const [name,setname]=useState('');
    const [firstname,setfirstname]=useState('');
    const [lastname,setlastname]=useState('');
    const [modal,setmodal]=useState(false);
    const [modalMsg, setModalMsg]=useState("not successful");
    const [fixed,setfixed]=useState(false);
    const [active,setactive]=useState(false);
    const [variety, setVariety]=useState(false);
    const [varietyData, setVarietyData]=useState();
    const [type, setType]=useState('');
    const [serviceId, setServiceId]=useState({});

    const [billerCode, setbillerCode]=useState('');
    
    const [billerCodeValid, setbillerCodevalid]=useState(false);
    const [billerCodeValidMsg, setbillerCodeValidMsg]=useState('');
    const [tvPlan, settvPlan]=useState('');
    const [tvPlanAmount, settvPlanAmount]=useState(0);
    const [validCustomerTvData, setvalidCustomerTvData]=useState(false);
    const [isTvPlanRenew, setisTvPlanRenew]=useState(false);
    const [serviceBiller, setserviceBiller]=useState('');
    const [subType, setsubType]=useState('renew');

    const [billerCodeErr, setbillerCodeErr]=useState(false);
    const [billerCodeErrMsg, setbillerCodeErrMsg]=useState('');

    // eslint-disable-next-line no-unused-vars
    const [transactionId,setTransactionId]=useState('');
    const [proceed,setProceed]=useState(false);
    
    const [selectedVariety, setSelectedVariety]=useState({});
    const [selectedVarietyFields, setselectedVarietyFields]=useState([]);
    const [selectedVarietyStatus, setselectedVarietyStatus]=useState(false);
    const [selectedVarietyId, setSelectedVarietyId] = useState(null);


    const [productExist,setProductExist] = useState(false);
    const [paymentType,setPaymentType] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [color, setColor] = useState(" #2C63EA");
    const [transactionRefId, setTransactionRefId] = useState(null);
    const [key, setKey] = useState(null);
    const [power, setpower] = useState(false);
    const [powerdata, setpowerdata] = useState(null);
    const [ amountErr, SetAmountErr] = useState(false)
    const [ amountSuccess, SetAmountSuccess] = useState(false)

    const [paymentInfo, setPaymentInfo] = useState({})

    const params = useParams();
    console.log(params)

 
    useEffect(()=>{
        const invoiceRef = params.uniqueRef;
        if(invoiceRef === null || invoiceRef === undefined ){
            // Display error and return to landing page
            window.location = "https://icadpay.com";
        }

        doPayReqProcessing(invoiceRef)


    },[])   




    useEffect(()=>{
        if(isBrowser){
            setProceed(true)
        }else{
            setProceed(false)
        }
        

     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const getValue = async(ref, transId)=>{
        const response = await axios.get('https://staging-api.icadpay.com/api/AltBiller/getValue?reqId='+ref);
        const data = response.data;
        setpowerdata(data)//

    }

    const doPayReqProcessing = async(invoiceRef) => {
        const response = await axios.get('https://staging-api.icadpay.com/api/payment-link?unid='+invoiceRef);
        console.log(response);

        /*
        *You can do some thing fancy here to show the customer what they are about to pay for*
        {
            "firstName": "emmanuel",
            "lastName": "yakubu",
            "email": "Emmanuelyak2@gmail.cpm",
            "uniqueRef": "EIHJV8DEBNOG058YHYCDWP6K7JSSCA",
            "description": "airtime",
            "amount": 10000.00,
            "phoneNumber": "08144069568",
            "publicKye": null
        }
         */


        const data = response.data;
        setPaymentInfo(data)
        console.log(paymentInfo)
        const payload = {
            key: data.publicKye === null ? 'live_YzM1ODg3YzY5MWVjZjFlYzhkOTQxMDU3NmMzM2NlYjc4YzQwYTU1M2ZkZjRmNjI5ZjQzOGQzZmM4ZmY3NzZmYQ' : data.publicKye, // this is not a demo key. 
            // key: 'test_MDE0OTY1Y2NjNDI0MjIyZjY1ZWYwOWQ1YzkyMmJjZTZkYzlhZDBiZDVkOTg5ZDBmZjllYTMyMzVjNTI4MmJmMQ', // this is a demo key.  
            // key: 'test_YzA1MmNmYzE0OTc1Y2QyM2ViNWUwNTIxOGMzZjA2MjI5N2M4ZDU3YmY5ZDg1ZmU1OWIwNWE1NDU0YjkzYTZkOQ', // this is a demo key.     
            email: data.email, // customer email 
            amount: parseFloat(data.amount), // amount to be processed
            currency: "NGN", // currency
            first_name: data.firstName,
            last_name: data.lastName,
            phone_number: data.phone, // customer's phone number (optional)
            customerId: data.email,
            ref: data, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            narration: data.description,
            isInvoice: true,
            service_bill: billServiceId,
            callback_url: `${window.location.href}`, // specified redirect URL (potional)
            // callback_url: '', // specified redirect URL (potional)
            callback: (response) => {
                console.log(response);
            },
            onSuccess: (response) => {
              console.log(response);
                // const ref = response
                // localStorage.set('transactionResponse',response )


                const req = {'transactionRef': response.reference, 'merchantTransactionRef': response.merchantTransactionRef}
                const responseUp = axios.post('https://staging-api.icadpay.com/api/payment-link', req);

                //Do anything else here e.g: show payment status 
                
                
            },
            onError: (response) => {
                //Do anything or go back to home page
                window.location = "https://icadpay.com"
            },
            onClose: () => {
                // alert('window closed');
                window.location = "https://icadpay.com";
            }
        }

        window.IcadPay.setup(payload);
    }

    

  return (
    <>
    <div className="paymentRQ-home">

      <div className="paymentRQ-modal">
        
      </div>
    </div>
    
        <div className="paymentRQ-header">
          <h2>Payment Request</h2>
        </div>
    
    
    </>
  )
}

export default PaymentRequest